// don't edit this file, it's generated by update-config.js
export default {
  env: 'staging',
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_QAJsXn4Qb',
    identityPoolId: 'us-east-1:01d07ec7-28a2-44da-af1c-1bc841e7ac3f',
    userPoolWebClientId: '2pgd26agm4gs39ubkjadfr7jna',
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: 'exoplanet-infrastructure-contentmanagementbucket-u21ou5hh3oqb',
      region: 'us-east-1',
    }
  },
}